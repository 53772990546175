import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../../examples/Footer";
import API_ROUTES from "../../../../config/apiConfig";
import DataTable from "../../../../examples/Tables/DataTable";
import taskList from "../../../../assets/images/task.png";
import EditIcon from "../../../../assets/images/edit-icon.png";
import Badge from "@mui/material/Badge";
import { green, orange } from "@mui/material/colors";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLanguageState } from "../../../../context/LanguageContext";
import loadTranslations from "../../../../utils/loadTranslations";
import SecondCard from "../SecondCard";
import ThirdCard from "../ThirdCard";
import FourthCard from "../FourthCard";

const ComposeReportDetail = () => {
  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      console.log(loadedTranslations.page_home.total_users);
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);

  const { campaign_id } = useParams();
  const [data, setData] = useState([]);
  const [count, setCount] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const group_id = localStorage.getItem("group_id");

    if (group_id && campaign_id) {
      axios
        .post(API_ROUTES.CAMPAIGN_REPORTS_COMPOSE_DETAIL, {
          group_id,
          campaign_id: campaign_id,
        })
        .then((response) => {
          console.log("API Response:", response.data);
          setData(response.data.data); // Store the response data
          setCount(response.data.count);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    }
  }, [campaign_id]);

  const handleEditClick = (id) => {
    // Handle edit button click
    console.log(`Edit clicked for ID: ${id}`);
  };

  const handleDelete = (id) => {
    const group_id = localStorage.getItem("group_id");

    if (group_id && id) {
      axios
        .post(API_ROUTES.CAMPAIGN_Detail_REPORTS, {
          group_id: parseInt(group_id, 10),
          sle_id: id,
        })
        .then((response) => {
          toast.success("Message deleted successfully");
          console.log("Delete response:", response.data);
          // Filter out the deleted item from the data
          setData((prevData) => prevData.filter((item) => item.id !== id));
        })
        .catch((error) => {
          toast.error("Error deleting message");
          console.error("Error deleting data:", error);
        });
    }
  };

  const columns = [
    { Header: "ID", accessor: "id", width: "10%" },
    {
      Header: "Message",
      accessor: "body",
      width: "40%",
      Cell: ({ value }) =>
        value.length > 100 ? `${value.substring(0, 100)}...` : value,
    },
    { Header: "CellPhone", accessor: "cellphone", width: "15%" },
    { Header: "Birthday", accessor: "dob", width: "10%" },
    {
      Header: "Status",
      accessor: "sent",
      width: "5%",
      Cell: ({ value }) =>
        value === 0 || value === 3 || value === 1 || value === 2 ? (
          <Badge
            badgeContent={
              value === 0
                ? translations.campaign_reports?.ready || "Pronta"
                : value === 1 || 2
                  ? translations.campaign_reports?.sent || "Enviada"
                  : translations.campaign_reports?.in_queue || "Na fila"
            }
            color="primary"
            sx={{
              "& .MuiBadge-badge": {
                backgroundColor:
                  value === 0
                    ? green[600] // Green for "Ready"
                    : value === 1
                      ? "rgb(63, 55, 201)" // Pink for "Sent"
                      : "#f72585", // Pink for "Queue"
                color: "white",
              },
            }}
          />
        ) : null,
    },
    { Header: "Date", accessor: "created_at", width: "10%" },
    {
      Header: "Actions",
      accessor: "actions",
      width: "10%",
      Cell: ({ row }) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <IconButton
            color="secondary"
            aria-label="delete"
            onClick={() => handleDelete(row.original.id)}
            style={{ color: "#f72585" }}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const tableData = {
    columns,
    rows: data.map((item) => ({
      ...item,
      dob: item.dob ? new Date(item.dob).toLocaleDateString() : "N/A", // Format dob if available
      created_at: new Date(item.created_at).toLocaleDateString(), // Format created_at
    })),
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mt={1.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mt={3}>
                <Card sx={{ width: "100%", cursor: "pointer" }}>
                  <MDBox p={2}>
                    <Grid container>
                      <Grid item xs={12} md={7} lg={12}>
                        <MDTypography variant="h2" sx={{ mt: 2, mb: 1, ml: 2 }}>
                          {count.ready || 0}
                        </MDTypography>
                      </Grid>
                      <MDTypography variant="h6" sx={{ mb: 1, ml: 2 }}>
                        {translations.campaign_reports?.ready || "Pronta"}
                      </MDTypography>
                    </Grid>
                  </MDBox>
                </Card>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mt={3}>
                <Card sx={{ width: "100%", cursor: "pointer" }}>
                  <MDBox p={2}>
                    <Grid container>
                      <Grid item xs={12} md={7} lg={12}>
                        <MDTypography variant="h2" sx={{ mt: 2, mb: 1, ml: 2 }}>
                          {count.sent || 0}
                        </MDTypography>
                      </Grid>
                      <MDTypography variant="h6" sx={{ mb: 1, ml: 2 }}>
                        {translations.campaign_reports?.sent || "Enviada"}
                      </MDTypography>
                    </Grid>
                  </MDBox>
                </Card>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mt={3}>
                <Card sx={{ width: "100%", cursor: "pointer" }}>
                  <MDBox p={2}>
                    <Grid container>
                      <Grid item xs={12} md={7} lg={12}>
                        <MDTypography variant="h2" sx={{ mt: 2, mb: 1, ml: 2 }}>
                          {count.queue || 0}
                        </MDTypography>
                      </Grid>
                      <MDTypography variant="h6" sx={{ mb: 1, ml: 2 }}>
                        {translations.campaign_reports?.in_queue || "Na fila"}
                      </MDTypography>
                    </Grid>
                  </MDBox>
                </Card>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>

        <Grid container>
          <Card sx={{ width: "100%", mt: 2 }}>
            <MDBox display="flex">
              <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                {translations.campaign_reports?.campaign_report_detail}
              </MDTypography>
            </MDBox>
            <MDBox p={2}>
              {loading ? (
                <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                  {translations.campaign_reports?.loading}
                </MDTypography>
              ) : data.length > 0 ? (
                <DataTable table={tableData} canSearch />
              ) : (
                <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                  No data found
                </MDTypography>
              )}
            </MDBox>
          </Card>
        </Grid>
      </MDBox>
      <Footer />
      {/* <ToastContainer /> */}
    </DashboardLayout>
  );
};

export default ComposeReportDetail;
