const english = {
  main_menu: {
    home: "Home",
    subscribers: "Your Public",
    leads: "Leads and Vips",
    automatize: "Automatiza",
    flowbuilder: "Flowbuilder",
    your_data: "Your Data",
    ai_assistant: "Ai Assistant",
    settings: "Settings",
    upload_image: "Upload Image",
    messages_bots: "Message Bots",
    bot_reports: "Bot Reports",
    bot_birthday: "Bot Birthday",
    bot_welcome: "Bot Welcome",
    bot_appointment: "Bot Appointment",
    bot_firstpurchase: "Bot 1st Purchase",
    bot_expiring: "Bot Expiring",
    campaigns: "Campaigns",
    campaigns_reports: "Campaigns Reports",
    create_new: "Create New",
    integrations: "Integrations",
    our_api: "Our API",
    hotmart: "Hotmart",
    google_sheets: "Google Sheets",
    woo_commerce: "Woo Commerce",
    instagram_bot: "Instagram Bot",
    cupons: "Cupons",
    new_promotion: "New Promotion",
    cupons_list: "Cupons List",
    alert_messages: "Alert Messages",
    whatsapp_cloudapi: "Whatsapp Cloud API",
    metrics: "Metrics",
    others: "Others",
    staff: "Staff",
    lottery: "Lottery",
  },
  buttons_and_common_words: {
    save: "Save",
    delete: "Delete",
    edit: "Edit",
    upload: "Upload",
    upload_images: "Upload Images",
    download: "Download",
    select: "Select",
    select_file: "Select File",
    logout: "Logout",
    login: "Login",
    signin: "Sign in",
    view: "View",
    details: "Details",
    export: "Export",
    import: "Import",
    settings: "Settings",
    send: "Send",
    message: "Message",
    image: "Image",
    tag: "Tag",
    tags: "Tags",
    column: "Column",
    actions: "Actions",
    back: "Back",
    enteries_per_page: "Entries per page",
    showing: "Showing",
    to: "to",
    entries: "Enteries",
    saving: "Saving...",
    are_you_sure: "Are you sure?",
    ok: "OK",
    cancel: "Cancel",
    export: "Export",
    import: "Import",
    upload_file: "Upload File",
    delete_file: "Delete All Pre-Imported",
    import_now: "Import Now",
  },
  page_home: {
    total_users: "Total Users",
    than_lastweek: "than last week",
    birthday_thismonth: "Birthdays this month",
    than_lastmonth: "than last month",
    than_yesterday: "than yesterday",
    automation_is: "automation is",
    aiassistant_is: "AI Assistant is",
    plans_and_invoices: "Plans and Invoices",
    connect_to_whatsapp: "Connect to Whatsapp",
    connected_to_whatsapp: "CONNECTED",
    update_connection_status: "Update Connection Status",
    logoff_true: "Disconnect/Clean",

    send_test_message: "Send Test Message",
    new_qrcode: "New QRCode",
    automation_restart_timer: "Automation Restart Timer",
    upload_logo_images: "Logos and Banners",
    timer: "Timer",
    your_logo: "Your Logo and Images",
    google_pixel: "Google Pixel",
    meta_pixel: "Meta Pixel",
    terms_of_use: "Terms of Use",
    birthday_today: "Birthday Today",
    read_qr: "Read QRCode",
    whatsapp_connection: "Whatsapp Connection",
    automation_status: "Automation Status",
    automation_multichanel_details: "Automation and Multichannel Details",
    multichanel_server: "Server",
    multichanel_token: "Multichannel Token",
  },
  page_subscribers: {
    subscribers_table: "Subscribers Table",
    your_public: "Your Public",
    name: "Name",
    mobile: "Mobile",
    dob: "DOB",
    company: "Company",
    lead: "Lead",
    created_at: "Created",
    updated_at: "Updated",
    actions: "Actions",
    click_up_image: "Click to upload image",
    search_bytag: "Search by TAG",
    select_tag: "Select a TAG",
    filter_bybirthday: "Filter by Birthday",
    meta_custom_public: "Meta Custom Public",
    add_new_subscriber: "Add Subscriber",
  },
  edit_subscribers: {
    fullname: "Full Name",
    firstname: "First Name",
    email: "Email",
    address: "Address",
    address_number: "Number",
    address_complement: "Complement",
    district: "District",
    city: "City",
    state: "State",
    country: "Country",
    zip: "Zip",
    tags: "Tags",
    column: "Column",
    add_tag: "Add Tags",
    responsable: "Responsable",
    product: "Product",
    value: "Value",
    total_visits: "Total Visits",
    extra1: "Extra1",
    extra2: "Extra2",
    last_purchase_date: "Last Purchase Date",
    expiring_date: "Expiring Date",
    legal_id: "LegalID",
    gender: "Gender",
  },
  additional_information_about_subscribers: {
    responsable: "Responsable",
    product: "Product",
    value: "Value",
    total_visits: "Total Visits",
    extra1: "Extra1",
    extra2: "Extra2",
    last_purchase_date: "Last Purchase Date",
    expiring_date: "Expiring Date",
  },
  page_leads_and_vips: {
    latest_subscribers: "Latest Subscribers",
  },
  page_automatize: {},
  flowbuilder: {
    open_flowbuilder: "Open Flowbuilder",
  },
  your_data: {
    question: "Variable",
    content: "Content",
  },
  ai_assistant: {},
  settings: {
    set_instructions: "Set Assistant Name and Instructions",
    assistant_name: "Assistant Name",
    assistant_instructions: "Assistant Instructions (Personality and Behavior)",
    copy_default_prompt: "Copy Default Prompt",
    send_training_file: "Send Training TXT File",
    number_uploaded_files: "Number of Uploaded Files",
    click_to_upload_txt_file: "Click to upload TXT File",
    list_delete_files: "List and Delete TXT Files",
    test_ai_using_file: "Test AI Using Sent TXT File",
    greetings_message: "Greetings Message",
    delete_assistant_file: "Are you sure you want to delete this file? ",
  },
  image_upload: {},
  message_bots: {},
  tags: {
    select: "Select a Tag",
    move_users: "Move Subscribers",
  },
  bots_reports: {
    ready_tosend: "Generate More Messages",
    in_queue: "In queue for next days",
    date: "Date",
    name: "Name",
    message: "Message",
    imagem: "Image",
    tags: "Tags",
    bots_reports: "Bot Reports",
    birthday: "Birthday",
    type: "Type",
    welcome: "Welcome",
    appointment: "Appointment",
    event_date: "Event Date",
  },
  ready_to_send: {},
  in_queue_for_next_days: {},
  birthday_bot: {
    birthday_bot: "Birthday Bot",
    antecipation_in_days:
      "How many days BEFORE the date you want to send the message",
    type_message_choose_image: "Type a message and choose image",
    tags: "Tags",
    placeholders:
      "Placeholders: %nome%, %celular%, %email%, %aniversario%, %tagid%, %empresa%, %endereco%, %numero%, %complemento%, %distrito%, %cidade%, %estado%, %cep%, %datacompra%, %datavencimento%, %operadora%, %produto%, %valor%, %extra1%, %extra2%, %extra3%",
  },
  welcome_bot: {
    welcome_bot: "Welcome Bot",
    delay_in_days: "How many days AFTER you want to send the message",
    placeholders:
      "Placeholders: %nome%, %celular%, %email%, %aniversario%, %tagid%, %empresa%, %endereco%, %numero%, %complemento%, %distrito%, %cidade%, %estado%, %cep%, %datacompra%, %datavencimento%, %operadora%, %produto%, %valor%, %extra1%, %extra2%, %extra3%",
  },
  first_purchase_bot: {
    firstpurchase_bot: "First Purchase Bot",
    delay_in_days: "How many days AFTER you want to send the message",
    placeholders:
      "Placeholders: %nome%, %celular%, %email%, %aniversario%, %tagid%, %empresa%, %endereco%, %numero%, %complemento%, %distrito%, %cidade%, %estado%, %cep%, %datacompra%, %datavencimento%, %operadora%, %produto%, %valor%, %extra1%, %extra2%, %extra3%",
  },
  expiring_bot: {
    expiring_bot: "Expiring Bot",
    antecipation_in_days: "How many days BEFORE you want to send the message",
    placeholders:
      "Placeholders: %nome%, %celular%, %email%, %aniversario%, %tagid%, %empresa%, %endereco%, %numero%, %complemento%, %distrito%, %cidade%, %estado%, %cep%, %datacompra%, %datavencimento%, %operadora%, %produto%, %valor%, %extra1%, %extra2%, %extra3%",
  },
  appointment_bot: {
    appoitment_bot: "Appoitment Bot",
    antecipation_in_days: "How many days BEFORE you want to send the message",
    placeholders:
      "Placeholders: %nome%, %celular%, %email%, %aniversario%, %tagid%, %empresa%, %endereco%, %numero%, %complemento%, %distrito%, %cidade%, %estado%, %cep%, %datacompra%, %datavencimento%, %operadora%, %produto%, %valor%, %extra1%, %extra2%, %extra3%",
  },
  campaigns: {},
  campaign_reports: {
    ready_tosend: "Ready to send",
    in_queue: "In queue for next days",
    daily_limit: "Daily Limit",
    web_delivery: "Web Delivery",
    web_integrator_message:
      "Turn it on to send the messages using the Web Automation system. For more than 100 deliveries per day, use our Windows App",
    enter_limit: "Enter Limit",
    campaign_report_detail: "Campaign Report Detail",
    loading: "Loading...",
    generate_more_message: "Generate More Messages",
    mesages30: "+ 30 messages",
    ready: "Ready",
    sent: "Sent",
    in_queue: "In queue",
  },
  create_new: {},
  integrations: {},
  our_api: {
    generate_token: "Generate Token",
    get_token: "Get Token",
    save_profile_api: "Save Profile API",
    api_url: "API url",
    copy_url: "Copy url",
    actions_api: "Actions API",
    copy_token: "Copy token",
  },
  create_tag: {
    create_tag: "Create tag",
    tag_name: "Tag Name",
  },
  create_column: {
    create_column: "New Stage",
    tag_name: "Column Name",
    stage: "Stage",
  },
  instagram_bot: {},
  cupons: {},
  metrics: {},
  others: {},
  your_public_import: {
    name: "name",
    mobile: "mobile",
    email: "email",
    dob: "dob",
    tagid: "tagid",
    company: "company",
    address: "address",
    number: "number",
    complement: "complement",
    district: "district",
    city: "city",
    state: "state",
    cep: "zipcode",
    cpf: "cpf",
    datepurchase: "datepurchase",
    dateexpiring: "dateexpiring",
    responsable: "responsable",
    product: "product",
    value: "value",
    extra1: "extra1",
    extra2: "extra2",
  },
  compose_message: {
    compose_message: "Compose Message",
    name_campaign: "Name the Message",
    enter_text: "Enter text",
    message_saved: "New Campaign Message saved successfully",
    edit_message: "Edit Message",
    message_name: "Message Name",
    message_body: "Message Body",
    message: "Lead status updated successfully",
  },
  tag: {
    total_subs: "Total Subscribers",
  },
  signin: {
    name_password_signin: "Enter your name and password to sign in",
    user: "User",
    password: "Password",
    remember_me: "Remember me",
    signin: "SIGN IN",
  },
  forms_survey: {
    forms_survey: "Forms and Survey",
  },
  generate_message: {
    generate_message: "Generate Campaign",
    select_message: "Select Message",
    area_code: "Area Code",
    area_code_all: "All area code",
    select_area_code: "Select area code ",
    generate_campaign_now: "GENERATE CAMPAIGN NOW",
    all_tags: "All Tags",
    limit_message: "Daily Limit has been updated",
  },
  multiChanel: {
    multi_channel: "Multichannel",
  },
  tags11: {
    destination_tag: "Select a destination TAG",
    migrate: "Migrate",
    edit: "Edit",
    delete: "Delete",
    delete_text:
      "You cant delete TAGs with subscribers, move them first to other TAG.",
  },
  column11: {
    destination_tag: "Select a destination Column",
    migrate: "Migrate",
    edit: "Edit",
    delete: "Delete",
    delete_text:
      "You cant delete TAGs with subscribers, move them first to other TAG.",
  },
  leads: {
    filter_tag: "Filter by tag",
    filter_operator: "Filter by Operator",
    search: "Search",
    manage_messages: "Manage Messages",
    manage_stages: "Manage Stages",
    first_column: "Intake",
    responsable: "Select Responsable",
    start_chat: "Start Chat",
    edit_profile: "Edit Profile",
    add: "Add",
    add_entrance: "Adicionar Assinante à Entrada",
    remove: "Remove from Leads",
    download_file_top_phrase: "Click here to download xls sample for import",
    can_be_imported: "Colllumn names that can be imported",
    not_welcome: "Do not generate Welcome messages",
  },
  edit_subs: {
    edit_subs: "Edit Subscribers",
  },
  whatsapp: {
    alert:
      "BEFORE you click at NEW QRCODE, open you whatsapp app in your mobile phone, go to Settings, Connected Devices, and click at Connect New Device.",
    read_qr_1: "Read QRCode at Server 1 (app.321dbase.com)",
    read_qr_4: "Read QRCode at Server 2 (srv10.crmstation.net)",
    read_qr_2: "Read QRCode at Server 2 (srv2.crmstation.net)",
  },
  login: {
    login: "Logged in Successfully",
  },
  multichanel: {
    chanel_admin: "Multichannel Admin",
  },
  signin1: {
    remember_me: "Remember me",
    signing_in: "Signing in...",
  },
  open_cards: {
    open_cards: "CARDS VIEW",
    previous: "Previous",
    next: "Next",
    company: "Company",
    get_profile_pic: "Get Profile Picture",
    delete_pic: "Delete Picture",
  },
  setting_AI: {
    saved_successfully: "Assistant data saved successfully",
    file_uploaded: "File uploaded successfully!",
    data_sent: "Assistant data sent successfully",
    delete_file: "File has been deleted successfully!",
    send_successfully: "Instructions sent successfully",
  },
  confirmationModel: {
    import_all:
      "Data will be imported from this temporary table to your main database in the background.",
  },
  leads12: {
    column_removed: "User removed from Leads",
    data_saved: "Data saved successfully!",
    avatar_delete: "Avatar removed successfully",
  },
};

export default english;
