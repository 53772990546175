import React, { useState, useEffect } from "react";
import axios from "axios";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Grid from "@mui/material/Grid";
import MDBox from "../../components/MDBox";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import SimpleCard from "./SimpleCard";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MDButton from "../../components/MDButton";
import loadTranslations from "../../utils/loadTranslations";
import { useLanguageState } from "../../context/LanguageContext";
// import avatarUrl from "../../assets/images/dummy.jpg";
import {
  Avatar,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import API_ROUTES from "../../config/apiConfig";
import { useNavigate } from "react-router-dom";
import MDTypography from "../../components/MDTypography";
import { default as Select1 } from "react-select";
import { toast } from "react-toastify";

const Leads = () => {
  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});
  const [state, setState] = useState({
    columns: {},
    cards: {},
    columnOrder: [],
  });

  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  // const [modalContent, setModalContent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // State for the dropdowns and search input
  const [firstDropdown, setFirstDropdown] = useState("");
  const [secondDropdown, setSecondDropdown] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  // const [tags, setTags] = useState([]);
  const [selectedTagId, setSelectedTagId] = useState(null);
  const [operator, setOperator] = useState([]);
  const [selectedOperatorId, setSelectedOperatorId] = useState(null);

  const [tagId, setTagId] = useState(0);
  const [operatorId, setOperatorId] = useState(0);
  const [search, setSearch] = useState("");

  const [modalOpenMessage, setModalOpenMessage] = useState(false);
  const [messageName, setMessageName] = useState("");
  const [messageBody, setMessageBody] = useState("");

  const handleOpenModalMessage = () => {
    setModalOpenMessage(true);
  };

  const handleCloseModalMessage = () => {
    setModalOpenMessage(false);
    setMessageName("");
    setMessageBody("");
  };
  const handleSaveMessage = () => {
    // Save logic here, e.g., sending data to the server
    console.log("Saved", { messageName, messageBody });
    handleCloseModalMessage();
  };

  const handleTagChange = (e) => setTagId(e.target.value);
  const handleOperatorChange = (e) => setOperatorId(e.target.value);
  const handleSearchChange = (e) => setSearch(e.target.value);
  const [columnsArray, setColumnsArray] = useState([]); // State to store columns as array

  const handleFirstDropdownChange = (event) => {
    setFirstDropdown(event.target.value);
  };

  const handleSecondDropdownChange = (event) => {
    setSecondDropdown(event.target.value);
  };

  const handleOpenModal = (card) => {
    setModalContent(card);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setModalContent(null);
  };

  // const handleSave = () => {
  //   // Handle save logic here
  //   console.log("Saved", modalContent);
  //   setModalOpen(false);
  // };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setModalContent((prevContent) => ({
  //     ...prevContent,
  //     [name]: value,
  //   }));
  // };

  const [groupId, setGroupId] = useState(localStorage.getItem("group_id"));

  useEffect(() => {
    // Monitor changes to group_id and update state
    const handleStorageChange = () => {
      setGroupId(localStorage.getItem("group_id"));
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      setTranslations(loadedTranslations);
      // Update the state with the translations
      setState((prevState) => ({
        ...prevState,
        columns: {
          ...prevState.columns,
          "column-1": {
            ...prevState.columns["column-1"],
            title: loadedTranslations.page_leads_and_vips?.latest_subscribers,
          },
        },
      }));
    };

    fetchTranslations();
  }, [language]);
  // const group_id = localStorage.getItem("group_id");
  // Assuming group_id is set correctly, you can retrieve it from localStorage like this:
  // const group_id = localStorage.getItem("group_id");

  const [operator1, setOperator1] = useState([]);

  const fetchImage = (url) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(true); // Image exists
      img.onerror = () => resolve(false); // Image does not exist
      img.src = url;
    });
  };

  const getAvatarUrl = async (item) => {
    let url;

    if (item.mobile) {
      url = `https://crmstation.net/avatar/${item.mobile}.jpg`;
      if (await fetchImage(url)) {
        console.log("Using mobile URL:", url);
        return url;
      }

      url = `https://crmstation.net/avatar/${item.mobile}.png`;
      if (await fetchImage(url)) {
        console.log("Using mobile URL:", url);
        return url;
      }

      if (item.mobile.substring(0, 4) > 5530) {
        const number = item.mobile.substring(0, 4) + item.mobile.substring(5);
        url = `https://crmstation.net/avatar/${number}.jpg`;
        if (await fetchImage(url)) {
          console.log("Using formatted mobile number URL:", url);
          return url;
        }

        const number1 = item.mobile.substring(0, 4) + item.mobile.substring(5);
        url = `https://crmstation.net/avatar/${number1}.png`;
        if (await fetchImage(url)) {
          console.log("Using formatted mobile number URL:", url);
          return url;
        }
      }
    }

    console.log("No valid image found, using default placeholder.");
    return "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png";
  };
  const fetchData = async () => {
    setLoading(true);
    setError(null);

    try {
      const operatorResponse = await axios.get(
        `${API_ROUTES.EDIT_SUBS_RESPONSABLE}?group_id=${groupId}`
      );

      const operatorsData = operatorResponse.data.data;
      const response1 = await axios.get(
        `https://crmstation.net/laravel/public/api/api_users_20_by_group?tag_id=${tagId}&opertor_id=${operatorId}&search=${search}&group_id=${groupId}`
      );

      let initialCards = {};
      let initialColumnOrder = [];
      let initialColumns = {};

      if (response1.data.status === "success") {
        const fetchedCards = {};
        const cardIds = await Promise.all(
          response1.data.data.map(async (item) => {
            const createdAt = item.updated_at
              ? new Date(item.updated_at).toLocaleDateString("en-GB")
              : "Date not found";

            let name = item.name || `ID: ${item.user_id}`;
            let email = item.mobile ? `${item.mobile}` : `ID: ${item.user_id}`;
            let avatarUrl = await getAvatarUrl(item);
            let company = item.company || "";

            const operator = operatorsData.find(
              (op) => op.id === item.operator_id
            );
            const operatorName = operator
              ? operator.opr_name
              : "Unknown Operator";

            fetchedCards[item.user_id] = {
              id: item.user_id.toString(),
              name,
              email,
              createdAt,
              avatarUrl,
              operatorName,
              company,
            };

            return item.user_id.toString();
          })
        );

        initialCards = fetchedCards;
        initialColumns = {
          "column-1": {
            id: "column-1",
            title: translations.leads?.first_column || "Entrada",
            cardIds,
          },
        };
        initialColumnOrder = ["column-1"];
      }

      // Fetching the second response and handling columns
      const response2 = await axios.get(
        `https://crmstation.net/laravel/public/api/api_get_columns_users_filter?tag_id=${tagId}&opertor_id=${operatorId}&search=${search}&group_id=${groupId}`
      );

      if (response2.data.status === "success") {
        const usersData = response2.data.data;
        const fetchedCards = {};
        const columns = {};
        const columnOrder = [];
        const columnsArrayData = []; // To store columns as an array

        for (const columnData of usersData) {
          const cardIds = await Promise.all(
            columnData.users.map(async (user) => {
              if (!user || !user.user_id) return null;

              const createdAt = user.created_at
                ? new Date(user.created_at).toLocaleDateString("en-GB")
                : "Date Not Available";

              let name = user.name || `ID: ${user.user_id}`;
              let email = user.mobile ? `${user.mobile}` : `No Email Provided`;
              let avatarUrl = await getAvatarUrl(user);
              let company = user.company || "";

              const operator = operatorsData.find(
                (op) => op.id === user.operator_id
              );
              const operatorName = operator
                ? operator.opr_name
                : "Unknown Operator";

              fetchedCards[user.user_id] = {
                id: user.user_id.toString(),
                name,
                email,
                createdAt,
                avatarUrl,
                operatorName,
                company,
              };

              return user.user_id.toString();
            })
          );

          // Add columns to state
          columns[`column-${columnData.id}`] = {
            id: `column-${columnData.id}`,
            title: columnData.column || "Unnamed Column",
            cardIds: cardIds.filter(Boolean), // Filter out null values
          };

          // Store column name in array
          columnsArrayData.push(columnData.column || "Unnamed Column");

          columnOrder.push(`column-${columnData.id}`);
        }

        // Update states
        setState((prevState) => ({
          ...prevState,
          cards: { ...initialCards, ...fetchedCards },
          columns: { ...initialColumns, ...columns },
          columnOrder: [...initialColumnOrder, ...columnOrder],
        }));

        setColumnsArray(columnsArrayData); // Save columns array to state
        console.log(columnsArrayData); // You can log the columnsArray to see the output
      } else {
        setError("Something went wrong while fetching user columns.");
      }
    } catch (error) {
      setError("Something went wrong");
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [tagId, operatorId, search, groupId]);

  const onDragEnd = (result) => {
    const { destination, source, draggableId, type } = result;

    // Log the drag result for debugging
    console.log("Drag result:", result);
    console.log("Columns array:", columnsArray);

    // If there's no destination (dropped outside any droppable area)
    if (!destination) {
      console.log("No valid drop destination");
      return;
    }

    // If the source and destination are the same, no need to do anything
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    // Handle column dragging if needed
    if (type === "column") {
      const newColumnOrder = Array.from(state.columnOrder);
      newColumnOrder.splice(source.index, 1);
      newColumnOrder.splice(destination.index, 0, draggableId);

      const newState = {
        ...state,
        columnOrder: newColumnOrder,
      };

      setState(newState);
      return;
    }

    // Handle card dragging between columns or within the same column
    const start = state.columns[source.droppableId];
    const finish = state.columns[destination.droppableId];

    // Moving within the same column
    if (start === finish) {
      const newCardIds = Array.from(start.cardIds);
      newCardIds.splice(source.index, 1);
      newCardIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        cardIds: newCardIds,
      };

      const newState = {
        ...state,
        columns: {
          ...state.columns,
          [newColumn.id]: newColumn,
        },
      };

      setState(newState);
      return;
    }

    // Moving between different columns
    const startCardIds = Array.from(start.cardIds);
    startCardIds.splice(source.index, 1); // Remove card from the source column
    const newStart = {
      ...start,
      cardIds: startCardIds,
    };

    const finishCardIds = Array.from(finish.cardIds);
    finishCardIds.splice(destination.index, 0, draggableId); // Add card to the destination column
    const newFinish = {
      ...finish,
      cardIds: finishCardIds,
    };

    const newState = {
      ...state,
      columns: {
        ...state.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    };

    setState(newState);

    // Log card details and initiate a dummy API call
    const movedCard = state.cards[draggableId];
    console.log("Moved card:", movedCard);

    // Prepare the new column data
    const newColumnData = {
      userId: movedCard.id,
      newColumnId: destination.droppableId,
      columnName: columnsArray[destination.droppableId] || "Unknown", // Default column name if not found
    };

    // Update the columnsArray, adding the "Entrance" column for the first entry
    if (destination.droppableId === "column-1") {
      columnsArray[destination.droppableId] = "Entrance";
    } else {
      columnsArray[destination.droppableId] = finish.title; // Set column title from finish column
    }

    console.log("Updated columnsArray:", columnsArray);

    // Function to post the updated column information and card movement
    const postCardMove = async () => {
      const postData = {
        user_id: movedCard.id,
        newColumnId: destination.droppableId,
        columnName: columnsArray[destination.droppableId],
        group_id: groupId,
      };

      console.log("Posting data:", postData);

      // Make the API call to update the column move
      try {
        const response = await axios.post(
          `${API_ROUTES.UPDATE_USER_COLUMN}`, // Add your actual API route here
          postData
        );
        // toast.success(translations.compose_message?.message);
        console.log("API call successful for card move", response.data);
      } catch (error) {
        console.error("Error in API call:", error);
      }
    };

    // Call the API to update the card move
    postCardMove();
  };

  const fetchDataTags = async () => {
    const group_id = localStorage.getItem("group_id");

    try {
      const response = await axios.get(
        `${API_ROUTES.TAGS_FETCH_DATA}?group_id=${group_id}`
      );
      setTags(response.data.tags);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchDataOperator = async () => {
    const group_id = localStorage.getItem("group_id");

    try {
      const response = await axios.get(
        `${API_ROUTES.EDIT_SUBS_RESPONSABLE}?group_id=${group_id}`
      );
      setOperator(response.data.data);
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  useEffect(() => {
    fetchDataOperator();
    fetchDataTags();
  }, []);

  const [modalContent, setModalContent] = React.useState({
    name: "",
    email: "",
    company: "",
    tags: "",
    message: "",
    avatarUrl: "",
    user_id: "",
    operator: "",
    birthday: "",

    // ...other fields
  });

  // Handle edit profile
  const handleEditProfile = (id) => {
    window.open(`/edit_subs/${id}`, "_blank");
  };

  const [selectedTags, setSelectedTags] = useState([]);
  const [tags, setTags] = useState([]);
  const group_id = localStorage.getItem("group_id");

  const [campaignOptions, setCampaignOptions] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState("");
  // const [loading, setLoading] = useState(true);
  // const [error, setError] = useState("");

  const fetchTagsAndCampaigns = async () => {
    try {
      // Fetch campaign data
      const campaignResponse = await axios.post(
        API_ROUTES.CAMPAING_REPORT_GET_DATA_COMPOSE,
        {
          group_id: localStorage.getItem("group_id"),
        }
      );

      // console.log("the data is ", campaignResponse.data.data);

      if (campaignResponse.data.status === "success") {
        const campaignData = campaignResponse.data.data.map((campaign) => ({
          id: campaign.id,
          name: campaign.campaign_name,
        }));
        setCampaignOptions(campaignData);
      } else {
        throw new Error("Failed to fetch campaign data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    // Fetch tags and campaign data
    fetchTagsAndCampaigns();
  }, []);

  useEffect(() => {
    // Function to fetch user tags data
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://crmstation.net/laravel/public/api/api_get_user_tags",
          { group_id: group_id, user_id: modalContent.id } // Update with actual group_id and user_id
        );

        if (response.data.status === "success") {
          const { tags: allTags, userTags } = response.data;

          setTags(allTags.map((tag) => ({ value: tag.id, label: tag.tag })));
          setSelectedTags(
            userTags.map((tag) => ({
              value: tag.tag_id,
              label: allTags.find((t) => t.id === tag.tag_id)?.tag || "",
            }))
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (modalContent?.id) {
      fetchData();
    }
  }, [modalContent?.id]); // Fetch data when modalContent changes

  const handleTagsChange = (selectedOptions) => {
    setSelectedTags(selectedOptions);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // If the field being updated is 'birthday', split the value into year, month, and day
    if (name === "birthday") {
      const [year, month, day] = value.split("-"); // Split the date into parts (YYYY-MM-DD)

      setModalContent((prevContent) => ({
        ...prevContent,
        dob_year: year,
        dob_month: month,
        dob_day: day,
      }));
    } else {
      // Handle other fields as usual
      setModalContent((prevContent) => ({
        ...prevContent,
        [name]: value,
      }));
    }
  };
  const handleSave = async (tags) => {
    const tags1 = tags.map((item) => item.value);
    console.log(tags1);
    // console.log(modalContent);

    try {
      await axios.post(
        "https://crmstation.net/laravel/public/api/api_update_user",
        {
          tags: JSON.stringify(tags1),
          user_id: modalContent.id,
          name: modalContent.name,
          mobile: modalContent.email,
          company: modalContent.company || "",
          birthday: modalContent.birthday,
          group_id: groupId,
        }
      );
      toast.success(translations.leads12?.data_saved);
    } catch (error) {
      toast.error("Failed to save data!");
    }
  };

  const handleSendUpdateLink = async () => {
    console.log("Triggered");
  };

  const handleSelectCampaign = async () => {
    try {
      await axios.post("https://dummyapi.io/api/select-campaign", {
        campaignId: selectedCampaign,
      });
      toast.success("Campaign selected successfully!");
    } catch (error) {
      toast.error("Failed to select campaign!");
    }
  };

  const handleEnviar = async () => {
    console.log("This is campaign:", selectedCampaign);

    const groupId = localStorage.getItem("group_id");

    try {
      const response = await axios.get(
        `https://321dbase.com/public/cronjobs/send_lead_sms.php?group_id=${groupId}&message_id=${selectedCampaign}&user_id=${modalContent.id}`
      );

      if (response.data.retorno === "true") {
        toast.success("Message sent successfully");
        // Handle success (e.g., show a success toast notification)
      } else {
        toast.error(response.data.info);
        // Handle error (e.g., show an error toast notification)
      }
    } catch (error) {
      console.error("Error sending message:", error);
      // Handle error (e.g., show an error toast notification)
    }
  };
  const handleDelete = async (modalContent) => {
    console.log("This is campaign:", modalContent.id);

    const groupId = localStorage.getItem("group_id");

    try {
      const response = await axios.post(API_ROUTES.API_REMOVE_INHERITANCE, {
        group_id: groupId,
        user_id: modalContent.id,
      });

      if (response.data) {
        toast.success(translations.leads12?.column_removed);
        fetchData();
        setModalOpen(false);
        // Handle success (e.g., show a success toast notification)
      } else {
        toast.error(response.data.message);
        // Handle error (e.g., show an error toast notification)
      }
    } catch (error) {
      console.error("Error sending message:", error);
      // Handle error (e.g., show an error toast notification)
    }
  };

  // const [operatorId, setOperatorId] = useState(null); // State for selected operator

  // Function to handle the operator selection
  const handleOperatorChangeSendApi = (event) => {
    setOperatorId(event.target.value);
  };

  // Function to handle saving the operator and sending the API request
  const handleOperatorSend = async () => {
    console.log("API triggered");

    try {
      const response = await axios.post(
        API_ROUTES.OPERATOR_SEND_API_EDIT_PROFILE,
        {
          user_id: modalContent.id, // Assuming modalContent.id is the user ID
          group_id: groupId, // Assuming groupId is defined elsewhere in your code
          operator_id: operatorId, // Selected operator ID
        }
      );

      toast.success(response.data.message); // Show success message
    } catch (error) {
      toast.error(error.response?.data?.message || "Error occurred"); // Handle error
    }
  };

  // State Variables
  const [searchQuery1, setSearchQuery1] = useState(""); // New variable for search
  const [suggestions, setSuggestions] = useState([]); // Suggestions array
  const [selectedUser, setSelectedUser] = useState(null); // Store selected user
  const [loading1, setLoading1] = useState(false); // Loading state for API call

  // Function to handle search input change
  const handleSearchChange1 = async (event) => {
    const searchValue = event.target.value;
    setSearchQuery1(searchValue); // Set the search value to show user input

    if (searchValue) {
      setLoading1(true); // Show loading state while fetching suggestions
      try {
        // Call API with tag_id = 0 and operator_id = 0, and use the search term in the query
        const response = await axios.get(
          `https://crmstation.net/laravel/public/api/api_users_by_search?limit=100&name=${searchValue}&group_id=${groupId}`
        );

        if (response.data && response.data.data) {
          setSuggestions(response.data.data); // Set the suggestions array with API response
        } else {
          setSuggestions([]); // Clear suggestions if no results
        }
      } catch (error) {
        console.error("Error fetching suggestions:", error);
        setSuggestions([]); // Clear suggestions on error
      } finally {
        setLoading1(false); // Stop loading after API call
      }
    } else {
      setSuggestions([]); // Clear suggestions when input is empty
    }
  };

  const handleAddSelectedToEntrance = () => {
    if (selectedUser) {
      const group_id = localStorage.getItem("group_id"); // Get group_id from localStorage

      if (!group_id) {
        alert("Group ID is missing in localStorage");
        return;
      }

      // Prepare data for the API request
      const data = {
        group_id: group_id,
        user_id: selectedUser.user_id,
      };

      // Perform the API call
      axios
        .post(
          "https://crmstation.net/laravel/public/api/api_move_user_entrance",
          data
        )
        .then((response) => {
          console.log("API response:", response.data);
          // Handle success, e.g., show a success message or perform further actions
          // toast.success("User moved to entrance successfully!");

          // Fetch updated data after successful post
          fetchData();
        })
        .catch((error) => {
          console.error("API call error:", error);
          // Handle errors, e.g., show an error message
          toast.error("Failed to move user to entrance.");
        });
    } else {
      toast.error("Please select a user first");
    }
  };

  // Handle when a suggestion is clicked
  const handleSuggestionClick = (user) => {
    setSelectedUser(user); // Set the selected user
    setSearchQuery1(user.name); // Fill input field with selected user's name
    setSuggestions([]); // Clear the suggestion dropdown after selecting
  };

  const [image, setImage] = useState("");
  const [loading2, setLoading2] = useState(false);

  const handleProfilePicture = async (email) => {
    setLoading2(true); // Start loading2 when button is clicked
    console.log(email);
    const name = localStorage.getItem("name");
    let number;
    const instancia = `${groupId}_${name}`;

    // Check if the first 4 characters are a number greater than 5530
    if (parseInt(email.substring(0, 4), 10) > 5530) {
      number = email.substring(0, 4) + email.substring(5);
    } else {
      number = `${email}@c.us`;
    }

    try {
      const response = await axios.post(
        "https://crmstation.net/laravel/public/api/api_latest_profileimage",
        {
          group_id: groupId,
          number: number,
          instancia: instancia,
        }
      );
      console.log(response);

      // Handle success or failure based on response.data.success
      if (response.data.success) {
        setImage(response.data.file_path); // Set the file_path as image
      } else {
        toast.error("Image not found"); // Show toast for failed request
      }
    } catch (error) {
      console.error("Error fetching profile picture:", error);
      toast.error("An error occurred while fetching the image.");
    } finally {
      setLoading2(false); // Stop loading2 once the request is done
    }
  };

  const handleDeleteProfile = async (avatarUrl) => {
    try {
      // Extract the file name from the URL
      const fileName = avatarUrl.split("/").pop();

      const response = await axios.post(
        "https://crmstation.net/laravel/public/api/api_delete_profileimage",
        {
          file_path: fileName,
        }
      );

      if (response.data.status) {
        toast.success(translations.leads12?.avatar_delete);
        fetchData();
      }
    } catch (error) {
      toast.error("Error occurred during deletion!");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox py={3}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            {/* First Dropdown */}
            <MDTypography variant="h6" sx={{ mb: 1 }}>
              {translations.leads?.filter_tag}
            </MDTypography>
            <Select
              fullWidth
              value={tagId}
              onChange={handleTagChange}
              displayEmpty
              sx={{ p: 1.5 }}
            >
              <MenuItem value={0}>{translations.leads?.all || "All"}</MenuItem>
              {tags.map((tag) => (
                <MenuItem key={tag.id} value={tag.id}>
                  {tag.tag}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          {/* Second Dropdown */}
          <Grid item xs={3}>
            {/* Second Dropdown */}
            <MDTypography variant="h6" sx={{ mb: 1 }}>
              {translations.leads?.filter_operator}
            </MDTypography>
            <Select
              fullWidth
              value={operatorId}
              onChange={handleOperatorChange}
              displayEmpty
              sx={{ p: 1.5 }}
              title={translations.leads?.responsable}
            >
              <MenuItem value={0}>{translations.leads?.all || "All"}</MenuItem>
              {operator.map((op) => (
                <MenuItem key={op.id} value={op.id}>
                  {op.opr_name}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          {/* Search Input Field */}
          <Grid item xs={2}>
            <MDTypography variant="h6" sx={{ mb: 1 }}>
              {translations.leads?.search}
            </MDTypography>
            <TextField
              fullWidth
              value={search}
              onChange={handleSearchChange} // Updated
              placeholder={translations.leads?.search}
              title={translations.leads?.search}
            />
          </Grid>

          {/* Manage Column Button */}
          <Grid item xs={2} sx={{ mt: 4 }}>
            <MDButton
              variant="gradient"
              color="dark"
              fullWidth
              onClick={() => {
                navigate("/column");
              }}
            >
              {translations.leads?.manage_stages}
            </MDButton>
          </Grid>
          {/* Manage Column Button */}
          {/* <Grid item xs={2} sx={{ mt: 4 }}>
            <MDButton
              variant="gradient"
              color="dark"
              fullWidth
              onClick={handleOpenModalMessage}
            >
              {translations.leads?.manage_messages}
            </MDButton>
          </Grid> */}
        </Grid>
      </MDBox>
      {/* JSX for the search bar with suggestions */}
      <MDBox>
        <Grid container spacing={2} alignItems="center">
          {/* Search Input Field */}
          <Grid item xs={4}>
            <MDTypography variant="h6" sx={{ mb: 1 }}>
              {translations.leads?.add_entrance}
            </MDTypography>
            <TextField
              fullWidth
              value={searchQuery1} // Show typed text in input
              onChange={handleSearchChange1}
              placeholder={translations.leads?.search || "Search..."}
              title={translations.leads?.search}
            />
            {/* Suggestions or Loading Box */}
            {loading1 ? (
              <List
                sx={{
                  mt: 1,
                  boxShadow: 3,
                  borderRadius: 2,
                  border: "1px solid #ccc",
                  maxHeight: 200,
                  overflowY: "auto",
                  position: "absolute",
                  backgroundColor: "#fff", // Ensure white background
                  zIndex: 10,
                  width: "95%", // Adjust width to be more narrow
                }}
              >
                <ListItem
                  button
                  sx={{
                    "&:hover": {
                      backgroundColor: "#f1f1f1", // Subtle hover effect
                    },
                    padding: "10px 15px", // Add some padding
                    backgroundColor: "#fff", // Ensure white background for each item
                  }}
                >
                  Loading...
                </ListItem>
              </List> // Show loading message
            ) : (
              suggestions.length > 0 && (
                <List
                  sx={{
                    mt: 1,
                    boxShadow: 3,
                    borderRadius: 2,
                    border: "1px solid #ccc",
                    maxHeight: 200,
                    overflowY: "auto",
                    position: "absolute",
                    backgroundColor: "#fff", // Ensure white background
                    zIndex: 10,
                    width: "95%", // Adjust width to be more narrow
                  }}
                >
                  {suggestions.map((user) => (
                    <ListItem
                      key={user.user_id}
                      button
                      onClick={() => handleSuggestionClick(user)}
                      sx={{
                        "&:hover": {
                          backgroundColor: "#f1f1f1", // Subtle hover effect
                        },
                        padding: "10px 15px", // Add some padding
                        backgroundColor: "#fff", // Ensure white background for each item
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar
                          src={user.avatar_url}
                          alt={user.name}
                          sx={{ width: 40, height: 40 }} // Adjust avatar size for better layout
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={user.name}
                        secondary={user.mobile}
                        primaryTypographyProps={{
                          style: { fontWeight: 500 }, // Make the user's name stand out
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              )
            )}
          </Grid>

          {/* Add Selected to Entrance Button */}
          <Grid item xs={2}>
            <MDButton
              variant="gradient"
              color="black1"
              fullWidth
              sx={{ mt: 4 }} // Adjusts margin to vertically align with input field
              onClick={handleAddSelectedToEntrance}
              style={{ color: "black" }}
            >
              {translations.leads?.add || "Adicionar"}
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox py={3}>
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <div style={{ position: "relative" }}>
            {/* Top Scrollbar */}
            <div
              className="scroll-top leads-scrollbar-container"
              style={{
                overflowX: "auto",
                whiteSpace: "nowrap",
                height: "16px", // Adjust height as necessary
                marginBottom: "5px", // Space between top scrollbar and content
              }}
              onScroll={(e) => {
                const bottomScrollbar =
                  document.querySelector(".scroll-container");
                if (bottomScrollbar) {
                  bottomScrollbar.scrollLeft = e.target.scrollLeft;
                }
              }}
            >
              <div
                className="scroll-content"
                style={{
                  width:
                    document.querySelector(".scroll-container")?.scrollWidth ||
                    "max-content",
                  height: "1px", // Ensure height is minimal so that it only shows the scrollbar
                }}
              ></div>
            </div>

            {/* Content with Bottom Scrollbar */}
            <div style={{ position: "relative", overflow: "hidden" }}>
              <div
                className="scroll-container leads-scrollbar-container"
                style={{
                  overflowX: "auto",
                  whiteSpace: "nowrap",
                  paddingBottom: "15px",
                }}
                onScroll={(e) => {
                  const topScrollbar = document.querySelector(".scroll-top");
                  if (topScrollbar) {
                    topScrollbar.scrollLeft = e.target.scrollLeft;
                  }
                }}
              >
                <div
                  className="scroll-content"
                  style={{ display: "inline-block" }}
                >
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable
                      droppableId="all-columns"
                      direction="horizontal"
                      type="column"
                    >
                      {(provided) => (
                        <Grid
                          container
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          spacing={3}
                          style={{ display: "flex", flexWrap: "nowrap" }}
                        >
                          {state.columnOrder.map((columnId, index) => {
                            const column = state.columns[columnId];
                            const cards = column.cardIds.map(
                              (cardId) => state.cards[cardId]
                            );

                            return (
                              <Draggable
                                key={column.id}
                                draggableId={column.id}
                                index={index}
                              >
                                {(provided) => (
                                  <Grid
                                    item
                                    {...provided.draggableProps}
                                    ref={provided.innerRef}
                                    style={{
                                      minWidth: "300px",
                                      ...provided.draggableProps.style,
                                    }}
                                  >
                                    <MDBox
                                      {...provided.dragHandleProps}
                                      mt={3}
                                      mb={3}
                                    >
                                      <h3>{column.title}</h3>
                                      <Droppable
                                        droppableId={column.id}
                                        type="card"
                                      >
                                        {(provided, snapshot) => (
                                          <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            style={{
                                              minHeight: "150px", // Ensures droppable area has height
                                              background:
                                                snapshot.isDraggingOver
                                                  ? "lightblue"
                                                  : "",
                                              padding: "8px",
                                              borderRadius: "4px",
                                            }}
                                          >
                                            {cards.length > 0 ? (
                                              cards.map((card, index) => (
                                                <Draggable
                                                  key={card.id}
                                                  draggableId={card.id}
                                                  index={index}
                                                >
                                                  {(provided) => (
                                                    <MDBox
                                                      mt={3}
                                                      mb={3}
                                                      ref={provided.innerRef}
                                                      {...provided.draggableProps}
                                                      {...provided.dragHandleProps}
                                                      onDoubleClick={() =>
                                                        handleOpenModal(card)
                                                      }
                                                    >
                                                      <SimpleCard
                                                        title={card.name}
                                                        email={card.email}
                                                        createdAt={
                                                          card.createdAt
                                                        }
                                                        avatarUrl={
                                                          card.avatarUrl
                                                        }
                                                        operatorName={
                                                          card.operatorName
                                                        }
                                                      />
                                                    </MDBox>
                                                  )}
                                                </Draggable>
                                              ))
                                            ) : (
                                              <MDTypography
                                                variant="h6"
                                                sx={{ mt: 4 }}
                                              ></MDTypography>
                                            )}
                                            {provided.placeholder}
                                          </div>
                                        )}
                                      </Droppable>
                                    </MDBox>
                                  </Grid>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </Grid>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </div>
            </div>
          </div>
        )}
      </MDBox>

      <Footer />

      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        closeAfterTransition
        sx={{ borderRadius: "10px", border: "1px solid white" }}
      >
        <Fade in={modalOpen}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: { xs: "90%", md: "80%" },
              maxWidth: "1200px",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: "10px",
              border: "1px solid white",
            }}
          >
            {modalContent && (
              <Grid container spacing={4}>
                {/* Left Side - Avatar and Buttons */}
                <Grid item xs={12} md={4}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      backgroundColor: "#f5f5f5",
                      padding: 2,
                      borderRadius: "10px",
                      height: "100%",
                    }}
                  >
                    <Avatar
                      src={modalContent.avatarUrl}
                      alt={modalContent.name}
                      sx={{
                        width: 200,
                        height: 200,
                        mb: 2,
                        border: "2px solid #000",
                        mt: 3,
                      }}
                    />

                    <MDButton
                      variant="gradient"
                      color="info"
                      fullWidth
                      sx={{ mt: 1 }}
                    >
                      {translations.leads?.start_chat}
                    </MDButton>
                    <MDButton
                      variant="gradient"
                      color="dark"
                      fullWidth
                      onClick={() => handleEditProfile(modalContent.id)}
                      sx={{ mt: 1 }}
                    >
                      {translations.leads?.edit_profile}
                    </MDButton>
                    <MDBox sx={{ display: "flex", width: "100%" }}>
                      <Grid container spacing={2}>
                        {/* First Button (col-6) */}
                        <Grid item xs={12} md={6}>
                          <MDButton
                            variant="gradient"
                            color="black1"
                            fullWidth
                            sx={{ mt: 1 }}
                            style={{ color: "black" }}
                            onClick={() =>
                              handleProfilePicture(modalContent.email)
                            }
                            disabled={loading2} // Disable button while loading2
                          >
                            {loading2
                              ? "Loading..."
                              : translations.open_cards?.get_profile_pic}
                          </MDButton>
                        </Grid>

                        {/* Second Button (col-6) */}
                        <Grid item xs={12} md={6}>
                          <MDButton
                            variant="gradient"
                            color="dark"
                            sx={{ mt: 1 }}
                            onClick={() =>
                              handleDeleteProfile(modalContent.avatarUrl)
                            }
                          >
                            {translations.open_cards?.delete_pic}
                          </MDButton>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </Box>
                </Grid>

                {/* Right Side - Form Fields */}
                <Grid item xs={12} md={8}>
                  <Grid container spacing={2}>
                    {/* First Row: Name and Email */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label={translations.page_subscribers?.name}
                        variant="outlined"
                        margin="normal"
                        name="name"
                        value={modalContent.name}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label={translations.page_subscribers?.mobile}
                        variant="outlined"
                        margin="normal"
                        name="email"
                        value={modalContent.email}
                        onChange={handleChange}
                      />
                    </Grid>

                    {/* Second Row: Company */}
                    <Grid item xs={6} style={{ marginTop: "-16px" }}>
                      <TextField
                        fullWidth
                        label={translations.open_cards?.company}
                        variant="outlined"
                        margin="normal"
                        name={translations.open_cards?.company}
                        value={modalContent.company || ""}
                        onChange={handleChange}
                      />
                    </Grid>
                    {/* Second Row: Birthday */}
                    <Grid item xs={6} style={{ marginTop: "-16px" }}>
                      <TextField
                        fullWidth
                        label="Birthday"
                        variant="outlined"
                        margin="normal"
                        name="birthday"
                        type="date" // Use the 'date' type to show a date picker
                        InputLabelProps={{
                          shrink: true, // Keep the label floated above the input
                        }}
                        value={modalContent.birthday || ""}
                        onChange={handleChange}
                      />
                    </Grid>

                    {/* Third Row: Tags Dropdown */}
                    <Grid item xs={12} md={6} lg={10}>
                      <Select1
                        placeholder={translations.tags?.select}
                        value={selectedTags}
                        options={tags}
                        onChange={handleTagsChange}
                        isMulti
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: "#e0e0e0",
                            primary: "#333333",
                          },
                        })}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={2}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        // marginTop: "6px",
                      }}
                    >
                      <MDButton
                        variant="gradient"
                        color="dark"
                        fullWidth
                        onClick={() => handleSave(selectedTags)}
                        sx={{ height: "43px" }} // Match TextField height
                      >
                        {translations.buttons_and_common_words?.save}
                      </MDButton>
                    </Grid>

                    {/* Fourth Row: Operator Selection and Send Button */}
                    <Grid item xs={10}>
                      <FormControl fullWidth>
                        <InputLabel id="operator-select-label">
                          {translations.leads?.responsable}
                        </InputLabel>
                        <Select
                          labelId="operator-select-label"
                          value={modalContent.operator || ""}
                          onChange={handleOperatorChangeSendApi}
                          sx={{ p: 1.5 }}
                        >
                          <MenuItem value={0} disabled>
                            {translations.leads?.responsable}
                          </MenuItem>
                          {operator.map((op) => (
                            <MenuItem key={op.id} value={op.id}>
                              {op.opr_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={2}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <MDButton
                        variant="gradient"
                        color="dark"
                        fullWidth
                        onClick={handleOperatorSend}
                        sx={{ height: "43px" }} // Match TextField height
                      >
                        {translations.buttons_and_common_words?.save}
                      </MDButton>
                    </Grid>

                    {/* Fifth Row: Select Message and Enviar Button */}
                    <Grid item xs={10}>
                      <FormControl fullWidth>
                        <InputLabel id="campaign-select-label">
                          {translations.generate_message?.select_message}
                        </InputLabel>
                        <Select
                          labelId="campaign-select-label"
                          value={selectedCampaign}
                          onChange={(e) => setSelectedCampaign(e.target.value)}
                          disabled={loading}
                          style={{ height: "2.7rem" }}
                        >
                          {loading ? (
                            <MenuItem disabled>Loading...</MenuItem>
                          ) : error ? (
                            <MenuItem disabled>{error}</MenuItem>
                          ) : campaignOptions.length > 0 ? (
                            campaignOptions.map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                {option.name}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem disabled>No campaigns available</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={2}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        // marginTop: "6px",
                      }}
                    >
                      <MDButton
                        variant="gradient"
                        color="info"
                        fullWidth
                        onClick={handleEnviar}
                        sx={{ height: "43px" }} // Match TextField height
                      >
                        {translations.buttons_and_common_words?.send}
                      </MDButton>
                    </Grid>
                  </Grid>
                  <MDBox style={{ display: "flex", justifyContent: "end" }}>
                    <MDButton
                      variant="gradient"
                      color="error"
                      fullWidth
                      onClick={() => handleDelete(modalContent)}
                      sx={{ height: "43px", width: "150px", mt: 3 }} // Match TextField height
                    >
                      {translations.leads?.remove}
                    </MDButton>
                  </MDBox>
                </Grid>
              </Grid>
            )}
          </Box>
        </Fade>
      </Modal>

      {/* Modal for messages */}

      {/* Modal for Managing Messages */}
      <Modal
        open={modalOpenMessage}
        onClose={handleCloseModalMessage}
        closeAfterTransition
      >
        <Fade in={modalOpenMessage}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: "10px",
            }}
          >
            <MDTypography variant="h6" mb={2}>
              {translations.leads?.manage_messages || "Manage Messages"}
            </MDTypography>
            <TextField
              fullWidth
              label="Message Name"
              variant="outlined"
              name="messageName"
              value={messageName}
              onChange={(e) => setMessageName(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Message Body"
              variant="outlined"
              name="messageBody"
              value={messageBody}
              onChange={(e) => setMessageBody(e.target.value)}
              multiline
              rows={4}
              sx={{ mb: 2 }}
            />
            <Grid
              container
              spacing={2}
              display="flex"
              justifyContent="space-between"
            >
              <Grid item>
                <MDButton
                  variant="gradient"
                  color="black1"
                  onClick={handleCloseModalMessage}
                  style={{ color: "black" }}
                >
                  {translations.buttons_and_common_words?.cancel}
                </MDButton>
              </Grid>
              <Grid item>
                <MDButton
                  variant="gradient"
                  color="dark"
                  onClick={handleSaveMessage}
                >
                  {translations.buttons_and_common_words?.save}
                </MDButton>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </DashboardLayout>
  );
};

export default Leads;
