import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import Grid from "@mui/material/Grid";
import MDBox from "../../../components/MDBox";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../examples/Footer";
import BookingCard from "../../../examples/Cards/BookingCard";
import thumbnail from "../../../assets/images/thumbnail.jpg";
import FourthCard from "./FourthCard";
import ThirdCard from "./ThirdCard";
import SecondCard from "./SecondCard";
import FirstCard from "./FirstCard";
import DataTable from "../../../examples/Tables/DataTable";
import { Card, CircularProgress, Tooltip } from "@mui/material";
import API_ROUTES from "../../../config/apiConfig";
import PolicyIcon from "@mui/icons-material/Policy";
import { useLanguageState } from "../../../context/LanguageContext";
import loadTranslations from "../../../utils/loadTranslations";
import taskList from "../../../assets/images/task.png";
import { useNavigate } from "react-router-dom";
import EditIcon from "../../../assets/images/edit-icon.png";
import EditCampaignModal from "./EditCampaignModal"; // Import the modal component
import { toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import MDButton from "../../../components/MDButton";

const CampaignReports = () => {
  const [tableData, setTableData] = useState({
    columns: [],
    rows: [],
  });
  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({}); // Initially null
  const [openModal, setOpenModal] = useState(false); // State to control modal visibility
  const [selectedCampaignId, setSelectedCampaignId] = useState(null); // State to hold selected campaign ID
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false); // State for dialog visibility
  const [campaignIdToDelete, setCampaignIdToDelete] = useState(null); // State to hold campaign ID to delete
  const [loading, setLoading] = useState(false); // State to track loading

  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      console.log("Loaded Translations: ", loadedTranslations); // Add this line
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);

  const [videoData, setVideoData] = useState([]);

  useEffect(() => {
    axios
      .get(
        "https://crmstation.net/laravel/public/api/api_get_videos?page=message_reports"
      )
      .then((response) => {
        setVideoData(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the video data!", error);
      });
  }, []);

  useEffect(() => {
    if (translations) {
      setTableData((prevState) => ({
        ...prevState,
        columns: [
          {
            Header: translations.bots_reports?.date || "Data",
            accessor: "date",
            width: "13%",
          },
          {
            Header: translations.bots_reports?.name || "Nome",
            accessor: "name",
            width: "15%",
          },
          {
            Header: translations.bots_reports?.message || "Mensagem",
            accessor: "message",
            width: "25%",
          },
          {
            Header: translations.bots_reports?.imagem || "Imagem",
            accessor: "image",
            width: "15%",
          },
          {
            Header: translations.bots_reports?.tags || "Tags",
            accessor: "tags",
            width: "17%",
          },
          {
            Header: translations.bots_reports?.actions || "Ações",
            accessor: "actions",
            width: "15%",
          },
        ],
      }));
    }
  }, [translations]);

  const navigate = useNavigate(); // Initialize navigate function

  const fetchData = async () => {
    const group_id = localStorage.getItem("group_id");

    try {
      const response = await axios.post(API_ROUTES.CAMPAING_REPORT_GET_DATA, {
        group_id,
      });

      const fetchedData = response.data.data.map((item) => ({
        campaign_id: item.id, // Ensure campaign_id is included
        date: formatDate(item.created_at.split(" ")[0]), // Format date to dd-mm-yyyy
        name: item.campaign_name,
        message:
          item.body.length > 100
            ? item.body.substring(0, 100) + "..."
            : item.body,
        image: (
          <img
            src={item.image}
            alt={item.campaign_name}
            style={{ width: "40%", height: "auto" }}
          />
        ),
        tags: item.tags, // Assuming tags is an array
        actions: (
          <div style={{ display: "flex", gap: "10px" }}>
            <Tooltip title={translations.tags11?.edit || "Editar"}>
            <IconButton
              
              aria-label="edit"
              onClick={() => handleEditClick(item.id)} // Handle edit button click
              style={{color:"#b5179e"}}
            >
              <img src={EditIcon} alt="" style={{ width: "20px" }} />
            </IconButton>
            </Tooltip>
            <Tooltip title={translations.buttons_and_common_words?.details || "Detalhes"}>
            <IconButton
              aria-label="edit"
              style={{ color: "#3f37c9" }}
              onClick={() => handlePolicyClick(item.id)} // Update onClick handler
            >
              <img src={taskList} alt="" style={{ width: "20px" }} />
            </IconButton>
            </Tooltip>
            <Tooltip title={translations.tags11?.delete || "Apagar"}>
            <IconButton
              color="secondary"
              aria-label="delete"
              onClick={() => handleDelete(item.id)}
              style={{ color: "#f72585" }}
            >
              <DeleteIcon />
            </IconButton>
            </Tooltip>
          </div>
        ),
      }));

      setTableData((prevState) => ({
        ...prevState,
        rows: fetchedData,
      }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [translations]);

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  };

  const handleEditClick = (campaignId) => {
    setSelectedCampaignId(campaignId);
    setOpenModal(true); // Open the modal when the button is clicked
  };

  const handleCloseModal = () => {
    setOpenModal(false); // Close the modal
  };

  const handlePolicyClick = (campaignId) => {
    navigate(`/campaign_report_detail/${campaignId}`);
  };

  const handleDelete = (campaignId) => {
    setCampaignIdToDelete(campaignId); // Store the campaign ID
    setOpenDeleteDialog(true); // Open the confirmation dialog
  };

  const confirmDelete = async () => {
    setLoading(true); // Start loading
    const group_id = localStorage.getItem("group_id");

    try {
      await axios.post(API_ROUTES.CAMPAIGN_REPORTS_DELETE, {
        group_id: parseInt(group_id, 10),
        campaign_id: campaignIdToDelete, // Use the stored campaign ID
      });

      toast.success("Deleted Successfully");

      // Remove the deleted campaign from the table data
      setTableData((prevState) => ({
        ...prevState,
        rows: prevState.rows.filter(
          (row) => row.campaign_id !== campaignIdToDelete
        ),
      }));
    } catch (error) {
      console.error("Error deleting campaign report:", error);
    } finally {
      setLoading(false); // End loading
      setOpenDeleteDialog(false); // Close the dialog after deletion
    }
  };

  const handleCancelDelete = () => {
    setOpenDeleteDialog(false); // Close the dialog without deleting
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mt={6}>
          <Grid container spacing={3}>
            {videoData.length > 0 ? (
              videoData.map((video, index) => (
                <Grid item xs={6} md={6} lg={2} key={index}>
                  <MDBox mt={3}>
                    <BookingCard
                      image={thumbnail}
                      title={video.title}
                      description={video.description} // Assuming the description is part of the API response
                      price={video.price} // Assuming the price is part of the API response
                      location={video.location} // Assuming the location is part of the API response
                      link={video.link}
                    />
                  </MDBox>
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                {/* <MDBox textAlign="center" mt={3}>
                  <h3>Video not found</h3>
                </MDBox> */}
              </Grid>
            )}
          </Grid>
        </MDBox>

        <MDBox mt={1.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mt={3}>
                <FirstCard />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mt={3}>
                <SecondCard />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mt={3}>
                <ThirdCard />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mt={3}>
                <FourthCard />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox mt={6}>
          <Card>
            <DataTable table={tableData} canSearch />
          </Card>
        </MDBox>
      </MDBox>
      <EditCampaignModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        campaignId={selectedCampaignId}
        onSave={fetchData} // Refresh table data on save
      />
      <Dialog
        open={openDeleteDialog}
        onClose={handleCancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm {translations.buttons_and_common_words?.delete}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {translations.buttons_and_common_words?.are_you_sure}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton
            onClick={handleCancelDelete}
            color="black1"
            style={{ color: "black" }}
            variant="gradient"
          >
            {translations.buttons_and_common_words?.cancel}
          </MDButton>
          <MDButton
            onClick={confirmDelete}
            color="error"
            disabled={loading} // Disable the button while loading
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" /> // Show loading indicator
            ) : (
              translations.buttons_and_common_words?.ok
            )}
          </MDButton>
        </DialogActions>
      </Dialog>
      <Footer />
    </DashboardLayout>
  );
};

export default CampaignReports;
