import React, { useState, useEffect } from "react";
import { Modal, Box, TextField, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { toast } from "react-toastify";
import API_ROUTES from "../../../config/apiConfig";
import MDButton from "../../../components/MDButton";
import { useLanguageState } from "../../../context/LanguageContext";
import loadTranslations from "../../../utils/loadTranslations";

const EditCampaignModal = ({ open, onClose, campaignId, onSave }) => {
  const [campaignName, setCampaignName] = useState("");
  const [smsBody, setSmsBody] = useState("");
  const [image, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open && campaignId) {
      fetchCampaignData();
    }
  }, [open, campaignId]);

  const fetchCampaignData = async () => {
    setLoading(true);
    const group_id = localStorage.getItem("group_id");
    try {
      const response = await axios.post(
        API_ROUTES.DATA_GET_EDIT_DATA_CAMPAIGN_REPORTS,
        {
          group_id: parseInt(group_id, 10),
          campaign_id: campaignId,
        }
      );

      const data = response.data.data[0]; // Accessing the first item in the data array
      console.log(data);

      setCampaignName(data.campaign_name);
      setSmsBody(data.body);
      setPreviewImage(data.image);
    } catch (error) {
      console.error("Error fetching campaign data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    setPreviewImage(URL.createObjectURL(file));
  };

  const handleSave = async () => {
    setLoading(true);
    const group_id = localStorage.getItem("group_id");
    const formData = new FormData();
    formData.append("campaign_id", campaignId);
    formData.append("campaign_name", campaignName);
    formData.append("sms_body", smsBody);
    formData.append("group_id", group_id);
    if (image) {
      formData.append("image", image);
    } else {
      formData.append("image_url", previewImage); // Send image URL if the image is not changed
    }

    try {
      await axios.post(
        API_ROUTES.DATA_GET_EDIT_UPDATE_DATA_CAMPAIGN_REPORTS,
        formData
      );
      toast.success("Campaign saved successfully!"); // Show success toast notification
      onSave(); // Call the onSave function to refresh the table data
      onClose(); // Close the modal
    } catch (error) {
      console.error("Error saving campaign data:", error);
      toast.error("Error saving campaign data."); // Show error toast notification
    } finally {
      setLoading(false);
    }
  };

  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);

  return (
    <>
      {/* <ToastContainer /> */}
      <Modal open={open} onClose={onClose}>
        <Box sx={{ ...modalStyle }}>
          <IconButton
            sx={{ position: "absolute", right: 8, top: 8 }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
          <h2>Edit Campaign</h2>
          <TextField
            fullWidth
            label="Campaign Name"
            value={campaignName}
            onChange={(e) => setCampaignName(e.target.value)}
            margin="normal"
          />
          <TextField
            fullWidth
            label="SMS Body"
            value={smsBody}
            onChange={(e) => setSmsBody(e.target.value)}
            margin="normal"
            multiline
            rows={4}
          />
          <Box mt={2}>
            <input type="file" onChange={handleImageChange} />
            {previewImage && (
              <Box mt={2} textAlign="center">
                <img
                  src={previewImage}
                  alt="Preview"
                  style={{ maxHeight: "300px" }}
                />
              </Box>
            )}
          </Box>
          <Box mt={2} style={{ display: "flex", justifyContent: "end" }}>
            <MDButton
              variant="gradient"
              color="dark"
              onClick={handleSave}
              disabled={loading}
            >
              {loading
                ? "Saving..."
                : translations.buttons_and_common_words?.save}
            </MDButton>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxHeight: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
  overflowY: "auto",
};

export default EditCampaignModal;
