import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import DataTable from "./DataTable";
import API_ROUTES from "../../config/apiConfig";
import loadTranslations from "../../utils/loadTranslations";
import { useLanguageState } from "../../context/LanguageContext";
import PolicyIcon from "@mui/icons-material/Policy";
import MDButton from "../../components/MDButton";
import { NavLink, useNavigate } from "react-router-dom";
import EditIcon from "../../assets/images/edit-icon.png";
import BookingCard from "../../examples/Cards/BookingCard";
import Select from "react-select";
import { toast } from "react-toastify";
import { ImportExport as ImportIcon } from "@mui/icons-material";

// Images
import thumbnail from "../../assets/images/thumbnail.jpg";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import { Padding } from "@mui/icons-material";
import ImportModal from "./ImportModal";
import UserExportButton from "./UserExportButton";
import EditModal from "./EditModal";
import ConfirmationDialog from "./ConfirmationDialog";

const Import = () => {
  const [openModal, setOpenModal] = useState(false);
  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [email, setEmail] = useState("");
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openImportDialog, setOpenImportDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  // API Call for Import
  const handleImportConfirm = async () => {
    try {
      const response = await axios.post(API_ROUTES.API_IMPORT_NOW, {
        group_id,
      });
      if (response.data.success) {
        toast.success("Import successful!");
        setOpenImportDialog(false);
      } else {
        toast.error("Import failed.");
      }
    } catch (error) {
      toast.error("An error occurred during import.");
    }
  };

  // API Call for Delete
  const handleDeleteConfirm = async () => {
    try {
      const response = await axios.post(API_ROUTES.API_IMPORT_NOW_DELETE, {
        group_id,
      });
      if (response.data.success) {
        toast.success("Data deleted successfully!");
        setOpenDeleteDialog(false);
      } else {
        toast.error("Deletion failed.");
      }
    } catch (error) {
      toast.error("An error occurred during deletion.");
    }
  };
  const handleEditClick = (user) => {
    setSelectedUser(user); // Set the selected user's data
    setEditModalOpen(true); // Open the modal
  };

  const handleEditModalClose = () => {
    setEditModalOpen(false); // Close the modal
    setSelectedUser(null); // Clear selected user
  };

  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);

  const group_id = localStorage.getItem("group_id");
  const [total, setTotal] = useState("");

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const handleTagChange = (selectedOptions) => {
    setSelectedTags(selectedOptions);
  };

  // Add state for errors
  const [errors, setErrors] = useState({});

  // Add validation and update the handleAddSubscriber function
  const handleAddSubscriber = async () => {
    const tagIDs = selectedTags.map((tag) => tag.value);
    const encodedTagIDs = encodeURIComponent(tagIDs.join(","));
    console.log("Tag IDs:", tagIDs);
    console.log("Encoded Tag IDs:", encodedTagIDs);

    // Validate required fields
    const newErrors = {};
    if (!name) newErrors.name = true;
    if (!company) newErrors.company = true;
    if (!cellphone) newErrors.cellphone = true;
    if (!email) newErrors.email = true;

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      toast.error("Please fill in all required fields.");
      return; // Exit function if validation fails
    }

    // Send data to backend API
    const subscriberData = {
      name,
      company,
      mobile: cellphone,
      email,
      groupid: group_id,
    };

    try {
      const response = await fetch(
        "https://321dbase.com/api/user_save_profile.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(subscriberData),
        }
      );

      const result = await response.json();
      console.log(response);

      if (response.ok) {
        toast.success("Subscriber added successfully!");
      } else {
        toast.error(`Error: ${result.message}`);
      }
    } catch (error) {
      toast.error("Failed to add subscriber");
    }

    handleClose();
  };

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);

  const handleDelete = (userId) => {
    console.log("delete", userId);

    setUserIdToDelete(userId);
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setUserIdToDelete(null);
  };

  const [reloadData, setReloadData] = useState(false);
  const reload1 = () => {
    setReloadData(!reloadData);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.post(
        `${API_ROUTES.API_DELETE_USER_GRP_IMPORT_DATA}`,
        {
          group_id,
          id: userIdToDelete,
        }
      );

      if (response) {
        toast.success("User Deleted Successfully");
        setReloadData(!reloadData); // Toggle reloadData to trigger re-fetch
      }
    } catch (error) {
      toast.error("Error deleting user.");
      console.error("Error deleting user:", error);
    } finally {
      handleCloseDeleteModal();
    }
  };

  const columns = translations.your_public_import
    ? [
        { Header: "ID", accessor: "ID", width: "10%" },
        {
          Header: translations.your_public_import.name || "Name",
          accessor: "name",
          width: "10%",
        },
        {
          Header: translations.your_public_import.mobile || "Mobile",
          accessor: "mobile",
          width: "10%",
        },
        {
          Header: translations.your_public_import.email || "Email",
          accessor: "email",
          width: "15%",
        },
        {
          Header: translations.your_public_import.dob || "DOB",
          accessor: "dob",
          width: "10%",
        },
        {
          Header: translations.your_public_import.tagid || "Tag ID",
          accessor: "tagid",
          width: "10%",
        },
        {
          Header: translations.your_public_import.company || "Company",
          accessor: "company",
          width: "10%",
        },
        {
          Header: translations.your_public_import.address || "Address",
          accessor: "address",
          width: "10%",
        },
        {
          Header: translations.your_public_import.city || "City",
          accessor: "city",
          width: "10%",
        },
        {
          Header: translations.your_public_import.state || "State",
          accessor: "state",
          width: "10%",
        },
        {
          Header: translations.your_public_import.zipcode || "ZIP Code",
          accessor: "zipcode",
          width: "10%",
        },
        {
          Header: translations.your_public_import.cpf || "CPF",
          accessor: "cpf",
          width: "10%",
        },
        {
          Header:
            translations.your_public_import.datepurchase || "First Order Date",
          accessor: "firstorder_date",
          width: "10%",
        },
        {
          Header:
            translations.your_public_import.dateexpiring || "Expiring Date",
          accessor: "expiring_date",
          width: "10%",
        },
        {
          Header: translations.your_public_import.responsable || "Operator",
          accessor: "operator",
          width: "10%",
        },
        {
          Header: translations.your_public_import.product || "Product",
          accessor: "product",
          width: "10%",
        },
        {
          Header: translations.your_public_import.value || "Value",
          accessor: "value",
          width: "10%",
        },
        {
          Header: translations.your_public_import.extra1 || "Extra 1",
          accessor: "extra1",
          width: "10%",
        },
        {
          Header: translations.your_public_import.extra2 || "Extra 2",
          accessor: "extra2",
          width: "10%",
        },
        {
          Header: translations.your_public_import.delete || "Actions",
          accessor: "actions",
          width: "10%",
        },
      ]
    : [];

  const formatData = (data) =>
    data.map((user) => ({
      ID: user.id,
      name: user.fname,
      mobile: user.mobile,
      email: user.email,
      dob: user.dob_day
        ? `${user.dob_day}/${user.dob_month}/${user.dob_year}`
        : "",
      tagid: user.tagid || "",
      company: user.company || "",
      address: user.address || "",
      city: user.city || "",
      state: user.state || "",
      zipcode: user.zipcode || "",
      cpf: user.cpf || "",
      firstorder_date: new Date(user.first_order_date).toLocaleDateString(),
      expiring_date: new Date(user.expiring_date).toLocaleDateString(),
      operator: user.operator || "",
      product: user.product || "",
      value: user.value || "",
      extra1: user.extra1 || "",
      extra2: user.extra2 || "",
      actions: (
        <div style={{ display: "flex", gap: "5px" }}>
          <IconButton
            color="primary"
            aria-label="edit"
            onClick={() => handleEditClick(user)} // Call handleEditClick with the selected user
          >
            <img src={EditIcon} alt="" style={{ width: "20px" }} />
          </IconButton>
          <IconButton
            aria-label="delete"
            style={{ color: "#f72585" }}
            onClick={() => handleDelete(user.id)}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    }));

  const [videoData, setVideoData] = useState([]);

  useEffect(() => {
    axios
      .get(
        "https://crmstation.net/laravel/public/api/api_get_videos?page=your_public"
      )
      .then((response) => {
        setVideoData(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the video data!", error);
      });
  }, []);

  const [selectedTag1, setSelectedTag1] = useState(0);
  const [dobFilter, setDobFilter] = useState("");

  const handleTagChange1 = (event) => {
    setSelectedTag1(event.target.value);
  };

  const handleDobChange = (event) => {
    setDobFilter(event.target.value);
  };

  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/tags");
  };

  // Function to fetch tags, now a separate function for easier reuse
  const fetchTags = async () => {
    const group_id = localStorage.getItem("group_id");
    try {
      const response = await fetch(
        `https://crmstation.net/laravel/public/api/api_users_by_group?group_id=${group_id}`
      );
      const data = await response.json();
      if (data.status === "success") {
        setTags(data.tags.map((tag) => ({ value: tag.id, label: tag.tag })));
        setTotal(data.total);
      }
    } catch (error) {
      console.error("Error fetching tags:", error);
    }
  };

  // Fetch tags when the component mounts or reloadData changes
  useEffect(() => {
    fetchTags();
  }, [reloadData]);

  // Function to toggle reloadData, triggering re-fetch
  const refreshTags = () => {
    setReloadData(!reloadData);
  };

  const [importModalOpen, setImportModalOpen] = useState(false);

  const handleImportClick = () => {
    setImportModalOpen(true);
  };

  const handleImportClose = () => {
    setImportModalOpen(false);
  };

  const [runSpecificCode, setRunSpecificCode] = useState(false); // Boolean to control specific code

  const makeTrue = () => {
    setRunSpecificCode(true);
  };
  // Handle button click inside ImportModal to hide parent component logic
  const hideParentComponent = () => {
    setImportModalOpen(false);
    setRunSpecificCode(false); // This can be used to hide logic in the parent if needed
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <MDBox mt={4}>
          <Card>
            <MDBox
              p={3}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <MDTypography variant="h5" fontWeight="medium">
                <span style={{ fontSize: "1.2rem" }}>
                  {" "}
                  {/* Apply smaller font size and greyish color */}
                </span>
                <span style={{ fontSize: "0.875rem", color: "grey" }}>
                  {" "}
                  {/* Apply smaller font size and greyish color( Total
                  Subscribers: {total} ) */}
                </span>
              </MDTypography>
              {/* ({total}) */}
              <span>
                {/* <UserExportButton /> */}
                <MDButton
                  variant="gradient"
                  color="dark"
                  onClick={handleImportClick}
                >
                  {translations.buttons_and_common_words?.upload_file}
                </MDButton>
                {/* Delete Button */}
                <MDButton
                  variant="gradient"
                  color="dark"
                  sx={{ mx: 2 }}
                  onClick={() => setOpenDeleteDialog(true)}
                >
                  {translations.buttons_and_common_words?.delete_file}
                </MDButton>
                {/* Import Button */}
                <MDButton
                  variant="gradient"
                  color="primary"
                  onClick={() => setOpenImportDialog(true)}
                >
                  {translations.buttons_and_common_words?.import_now}
                </MDButton>

                {/* Import Confirmation Dialog */}
                <ConfirmationDialog
                  open={openImportDialog}
                  handleClose={() => setOpenImportDialog(false)}
                  handleConfirm={handleImportConfirm}
                  dialogText={translations.confirmationModel?.import_all}
                  confirmText={translations.buttons_and_common_words?.ok}
                  cancelText={translations.buttons_and_common_words?.cancel}
                />

                {/* Delete Confirmation Dialog */}
                <ConfirmationDialog
                  open={openDeleteDialog}
                  handleClose={() => setOpenDeleteDialog(false)}
                  handleConfirm={handleDeleteConfirm}
                  dialogText={
                    translations.buttons_and_common_words?.are_you_sure
                  }
                  confirmText={translations.buttons_and_common_words?.ok}
                  cancelText={translations.buttons_and_common_words?.cancel}
                />
              </span>
            </MDBox>
            <ImportModal
              open={importModalOpen}
              onClose={handleImportClose}
              group_id={group_id}
              refreshTags={refreshTags}
              runSpecificCode={runSpecificCode}
              hideParentComponent={hideParentComponent}
              makeTrue={makeTrue}
              reloadData={reload1}
            />
            {columns.length > 0 && (
              <DataTable
                columns={columns}
                apiEndpoint={API_ROUTES.API_IMPORT_DATA}
                formatData={formatData}
                tag={selectedTag1}
                total={total}
                canSearch
                reloadData={reloadData}
              />
            )}
          </Card>
        </MDBox>
      </MDBox>
      <Footer />
      {/* Edit Modal */}
      {selectedUser && (
        <EditModal
          open={editModalOpen}
          onClose={handleEditModalClose}
          user={selectedUser}
        />
      )}
      {/* Modal */}
      <Modal open={openModal} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" component="h2">
            {translations.page_subscribers?.add_new_subscriber}
          </Typography>
          <Grid container spacing={2} mt={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={`${translations.edit_subscribers?.fullname}`}
                placeholder={`${translations.edit_subscribers?.fullname}`}
                variant="outlined"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  setErrors((prev) => ({ ...prev, name: false }));
                }}
                error={!!errors.name} // Show red border if there's an error
                helperText={errors.name ? "This field is required" : ""}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={`${translations.page_subscribers?.company}`}
                placeholder={`${translations.page_subscribers?.company}`}
                variant="outlined"
                value={company}
                onChange={(e) => {
                  setCompany(e.target.value);
                  setErrors((prev) => ({ ...prev, company: false }));
                }}
                error={!!errors.company} // Show red border if there's an error
                helperText={errors.company ? "This field is required" : ""}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={`${translations.page_subscribers?.mobile}`}
                placeholder={`${translations.page_subscribers?.mobile}`}
                variant="outlined"
                value={cellphone}
                onChange={(e) => {
                  setCellphone(e.target.value);
                  setErrors((prev) => ({ ...prev, cellphone: false }));
                }}
                error={!!errors.cellphone} // Show red border if there's an error
                helperText={errors.cellphone ? "This field is required" : ""}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={`${translations.edit_subscribers?.email}`}
                placeholder={`${translations.edit_subscribers?.email}`}
                variant="outlined"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setErrors((prev) => ({ ...prev, email: false }));
                }}
                error={!!errors.email} // Show red border if there's an error
                helperText={errors.email ? "This field is required" : ""}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                isMulti
                options={tags}
                value={selectedTags}
                onChange={handleTagChange}
                placeholder={translations.page_subscribers?.select_tag}
              />
            </Grid>
          </Grid>
          <Box mt={3} display="flex" justifyContent="flex-end" gap={2}>
            <MDButton
              variant="gradient"
              color="black1"
              onClick={handleClose}
              style={{ color: "black" }}
            >
              {translations.buttons_and_common_words?.cancel}
            </MDButton>
            <MDButton
              variant="gradient"
              color="dark"
              onClick={handleAddSubscriber}
            >
              {translations.buttons_and_common_words?.save}
            </MDButton>
          </Box>
        </Box>
      </Modal>

      {/* Modal deletion */}
      <Modal
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        aria-labelledby="delete-confirmation-modal"
        aria-describedby="delete-confirmation-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography
            id="delete-confirmation-modal-title"
            variant="h6"
            component="h2"
          >
            {translations.buttons_and_common_words?.are_you_sure}
          </Typography>
          <Box sx={{ mt: 2 }}>
            <MDButton
              onClick={handleCloseDeleteModal}
              color="black1"
              variant="gradient"
              style={{ color: "black" }}
            >
              {translations.buttons_and_common_words?.cancel}
            </MDButton>
            <MDButton
              onClick={handleConfirmDelete}
              color="error"
              variant="gradient"
              style={{ marginLeft: "5px" }}
            >
              {translations.buttons_and_common_words?.ok}
            </MDButton>
          </Box>
        </Box>
      </Modal>
    </DashboardLayout>
  );
};

export default Import;
